import React from 'react'

const WipSelectionTemplate = () => {
  return (
    <>
      <h1>WIP Selection</h1>
    </>
  )
}

export default WipSelectionTemplate
